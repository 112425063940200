import React from 'react'
import HeroSection from '../../common/components/organism/HeroSection'
import { Lang } from '@subtropico/shared/types'
import { PagePart } from '../../common/types'
import { sanityImageURL } from '../../common/utils'
import SimpleContentBlock  from '../../common/components/organism/SimpleContentBlock'
import CardWide  from '../../common/components/organism/CardWide'
import ContactForm from '../../common/components/organism/ContactForm'
import CardCollection  from '../../common/components/organism/CardCollection'
import { IframeRender } from '../../common/components/atoms'
import AgentList from '../../common/components/organism/AgentList'
import MarketList from '../../common/components/organism/MarketList'

interface IProp {
  part: PagePart,
  id: number
  lang: Lang
}

const PagePartRenderer: React.FC<IProp> = ({ part, id, lang }) => {

  switch(part._type) {
    case 'hero':
      return <HeroSection
      lang={lang}
      imageUrl={sanityImageURL(part?.image)!}
      partStyle={part?.partStyle || 'hero-tall'}
      overlayCard={part?.overlayCard?.[0] || null}
      />

    case 'simpleContentBlock':
      return <SimpleContentBlock body={part?.body} lang={lang} />

    case 'mapRender':
      return <IframeRender source={part?.source} width={part?.width} height={part?.height} />

    case 'cardWide':
      return <CardWide cardStyle={part?.cardStyle} cardHeading={part?.cardHeading} button={part?.button} image={part?.image} body={part?.body} />

    case 'cardCollection':
      return <CardCollection cardCollectionStyle={part?.cardCollectionStyle} cards={part?.cards} intro={part?.intro} />

    case 'formPart':
      return <ContactForm />

    case 'agentsList':
      return <AgentList partStyle={part?.partStyle} />

    case 'marketList':
      return <MarketList />

    default:
      return <div key={id} className="border-red-500 border">Missing Component Handler for type {part._type} </div>
  }
}

export default PagePartRenderer;
