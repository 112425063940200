import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { ProductList, List, AgentListStyle } from "../../../types/index"
import { useLocation } from "@reach/router"
import AgentCard from "../Cards/AgentCard";
import { Maxwidth, Selector } from "../../atoms"
import { sanityImageURL } from '../../../utils'
import { useGlobalStateContext } from '../../context/app'
import useScrollDirection from '@subtropico/shared/src/hooks/useScrollDirection';

const useProductsList = () => {
  const { agentsList, distributionCenters, marketList }: ProductList = useStaticQuery(
    graphql`
      query AllAgentList {
        agentsList: allSanitySubtropicoMarketAgent {
          nodes {
            image: _rawImage
            apples
            bananas
            carrots
            cell_number
            citrus
            email
            fax_number
            fruit
            garlic
            general
            ginger
            grapes
            id
            importid
            logo
            manager
            name
            onions
            potatoes
            subtropical_fruits
            tomatoes
            vegetables
            watermelon
            distributionCenter {
              name {
                af
                en
              }
            }
            market {
              name {
                en
                af
              }
              province {
                af
                en
              }
            }
          }
        }
        distributionCenters: allSanitySubtropicoDistributionCenter {
          nodes {
            name {
              af
              en
            }
          }
        }
        marketList: allSanitySubtropicoMarket {
          nodes {
            name {
              af
              en
            }
            province {
              af
              en
            }
          }
        }
      }
    `)
  return { agentsList, distributionCenters, marketList }
}

const commodityList = ['All commodities', 'Bananas', 'Carrots', 'Fruit', 'Onions', 'Potatoes', 'Tomatoes', 'Vegetables', 'Apples', 'Citrus', 'Garlic', 'General', 'Ginger', 'Grapes', 'Watermelon', 'Subtropical Fruits']

interface IAgentList {
  partStyle?: AgentListStyle
}

const AgentList: React.FC<IAgentList> = ({ partStyle }) => {

  const { pageContextState } = useGlobalStateContext()
  const { agentsList, marketList, distributionCenters } = useProductsList()
  const location = useLocation();
  const [loading, setLoading] = useState(true)
  const [allAgentList, setAllAgentList] = useState<List[]>([])
  const [distCenters, setDistCenters] = useState<string[]>([])
  const [markList, setMarkList] = useState<string[]>([])
  const [filterSelector, setFilterSelector] = useState({
    commodity: 'Select a commodity',
    distributionCenter: 'Select a Distribution centre',
    market: 'Select one of our markets',
  })
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false)

  const { isScrollingDown } = useScrollDirection();

  useEffect(() => {
    if (location.search) {
      const search = location.search.replace('?', '').split('=')

      if (search[0] === 'commodity') {
        const _commodityValue = search[1].replaceAll('%20', ' ').replaceAll('+', ' ')
        setFilterSelector((prev) => ({ ...prev, commodity: _commodityValue }))
      }
    }
  }, [location])

  useEffect(() => {
    const _centers: string[] = ['All Distribution centers']
    distributionCenters?.nodes?.map((center) => {
      _centers.push(center.name?.[pageContextState?.locale!]!)
    })

    setDistCenters(_centers)
  }, [distributionCenters, pageContextState?.locale])

  useEffect(() => {
    const _markets: string[] = ['All Provinces']
    marketList?.nodes?.map((center) => {
      center.province?.[pageContextState?.locale!] && _markets.push(center.province?.[pageContextState?.locale!]!)
    })
    const _marketList = Array.from(new Set(_markets))
    setMarkList(_marketList)
  }, [marketList, pageContextState?.locale])

  useEffect(() => {
    setLoading(true)
    const isCommoditySelected = filterSelector.commodity === 'Select a commodity' || filterSelector.commodity === 'All commodities'
    const isDistSelected = filterSelector.distributionCenter === 'Select a Distribution centre' || filterSelector.distributionCenter === 'All Distribution centers'
    const isMarketSelected = filterSelector.market === 'Select one of our markets' || filterSelector.market === 'All Provinces'

    const _agentsList = agentsList?.nodes || []
    let filteredList: List[] | undefined = [..._agentsList]

    if (isCommoditySelected) {
      filteredList = filteredList
    } else {
      let _commodity = filterSelector?.commodity?.toLowerCase()?.replace(' ', '_')
      filteredList = agentsList?.nodes?.filter?.((list: any) => list?.[_commodity])
    }

    if (isMarketSelected) {
      filteredList = filteredList
    } else {
      filteredList = filteredList?.filter((list) => list?.market.province?.[pageContextState?.locale!] === filterSelector.market)
    }

    if (isDistSelected) {
      filteredList = filteredList
    } else {
      filteredList = filteredList?.filter((list) => list?.distributionCenter?.name?.[pageContextState?.locale!] === filterSelector.distributionCenter)
    }

    setAllAgentList(filteredList || [])
    setLoading(false)
    setShowLoadMore(false)
  }, [filterSelector])

  useEffect(() => {
    setLoading(true)
    if (agentsList?.nodes.length) {
      const allList = [...agentsList?.nodes]
      if (allList.length > 12) {
        allList.length = 12
        setShowLoadMore(true)
      }
      setAllAgentList(allList)
    }
    setLoading(false)
  }, [agentsList])

  const showAllAgents = () => {
    setAllAgentList(agentsList?.nodes!)
    setShowLoadMore(false)
  }

  const updateCommodity = (value: string) => {
    setFilterSelector((prev) => ({ ...prev, commodity: value }))
  }
  const updateDistributionCenter = (value: string) => {
    setFilterSelector((prev) => ({ ...prev, distributionCenter: value }))
  }
  const updateMarket = (value: string) => {
    setFilterSelector((prev) => ({ ...prev, market: value }))
  }

  return (
    <section className="my-10">
      <div className={`sticky z-10 ${isScrollingDown ? 'top-0' : 'top-28'}`}>
        <div className="my-8 shadow-bottom bg-white">
          <Maxwidth>
            <div className="grid md:grid-cols-3 gap-10">
              <Selector selectorOptions={commodityList} value={filterSelector.commodity} setValue={updateCommodity} hoverColor={partStyle} />
              <Selector selectorOptions={markList} value={filterSelector.market} setValue={updateMarket} hoverColor={partStyle} />
              <Selector selectorOptions={distCenters} value={filterSelector.distributionCenter} setValue={updateDistributionCenter} hoverColor={partStyle} />
            </div>
          </Maxwidth>
        </div>
      </div>
      <div id="listing" className="-mt-60 pt-60 md:-mt-48 md:pt-48 relative z-0">
        <Maxwidth>
          {loading ? <div className="animate__animated animate__bounce animate__slow animate__infinite flex justify-center items-center my-10 text-2xl text-orange-red">
            Loading...
          </div> : allAgentList?.length ?
            <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-10 animate__animated animate__fadeIn animate__slow">
              {allAgentList?.sort((a, b) => a?.name?.localeCompare(b.name))?.map((agent, index) => {
                return (
                  <AgentCard
                    lang={pageContextState?.locale!}
                    image={agent?.image ? { src: agent?.image?._upload ? agent?.image?._upload?.previewImage : sanityImageURL(agent?.image), alt: agent?.name } : undefined}
                    key={index}
                    businessName={agent?.market?.name?.[pageContextState?.locale!]!}
                    province={agent.market?.province?.[pageContextState?.locale!]}
                    agentLogo={agent?.logo}
                    agentName={agent?.name}
                    isManager={agent?.manager}
                    contactInfo={{
                      fax: agent?.fax_number || '',
                      cell: agent?.cell_number || '',
                      email: agent?.email || ''
                    }}
                    className="animate__animated animate__zoomIn animate__faster"
                    fruitData={{
                      apples: !!agent.apples,
                      bananas: !!agent.bananas,
                      carrots: !!agent.carrots,
                      citrus: !!agent.citrus,
                      fruit: !!agent.fruit,
                      garlic: !!agent.garlic,
                      ginger: !!agent.ginger,
                      grapes: !!agent.grapes,
                      onions: !!agent.onions,
                      potatoes: !!agent.potatoes,
                      subtropical_fruits: !!agent.subtropical_fruits,
                      tomatoes: !!agent.tomatoes,
                      vegetables: !!agent.vegetables,
                      watermelon: !!agent.watermelon,
                      general: !!agent.general,
                    }}
                  />
                )
              }
              )}
            </div> : <div className="flex justify-center items-center my-10 text-2xl text-payne-grey">
              No Items match your search
            </div>}
          {showLoadMore ?
            <div className="text-center my-14">
              <button onClick={showAllAgents} className="bg-orange-red border-2 border-orange-red text-white py-4 px-14 transition-colors hover:bg-transparent duration-500 hover:text-orange-red">Load All Agents</button>
            </div>
            : ''}
        </Maxwidth>
      </div>
    </section>
  )
}

export default AgentList
