import React from 'react'
import {
  ManagerIcon,
  NoPhoto,
  BananaIcon,
  FruitsIcon,
  PotatoesIcon,
  TomatoesIcon,
  VegetablesIcon,
  Apple,
  Carrot,
  Citrus,
  Garlic,
  General,
  Ginger,
  Grapes,
  Onions,
  WaterMelon,
} from '../../../SVG'

import { Text, Image, Link } from '@subtropico/shared'
import { Lang } from '@subtropico/shared/types'
import { Logo } from '../../../../types/template';

import citifresh from '../../../../images/citifresh-logo.png';
import delta from '../../../../images/Delta-logo.png';
import DWfresh from '../../../../images/DW-Fresh-logo.png';
import GWpoole from '../../../../images/GWpoole-logo.png';
import Kimberley from '../../../../images/Kimberley-logo.png';
import Protea from '../../../../images/Protea-logo.png';
import subtropico from '../../../../images/subtropico-icon.png';
import wenpro from '../../../../images/wenpro.png';
import zire from '../../../../images/zire.png';
import modise from '../../../../images/modise.png';

interface IAgentCard {
  image?: {src?: string | null, alt?: string}
  agentLogo: Logo
  agentName: string
  businessName: string,
  province?: string,
  className: string
  contactInfo: {
   [key in contactInfoDataType]: string
  },
  fruitData: fruitDataAvailableType
  isManager?: boolean
  lang: Lang
}

type contactInfoDataType = 'fax' | 'cell' | 'email' // 'phone'

type fruitCategoryType = 'apples'| 'bananas' | 'carrots' | 'citrus' | 'fruit' | 'garlic' | 'ginger' | 'grapes' | 'onions'
| 'potatoes' | 'subtropical_fruits' | 'tomatoes' | 'vegetables' | 'watermelon' | 'general';
type fruitDataAvailableType = {
  [key in fruitCategoryType]: boolean
}

const AgentCard: React.FC<IAgentCard> = ({ image, agentLogo, agentName, businessName, contactInfo, isManager = false, province, fruitData, className, lang }) => {

  const renderAgentLogo = () => {
    switch (agentLogo) {
      case 'citifresh':
        return citifresh;
      case 'delta':
        return delta;
      case 'dw fresh':
        return DWfresh;
      case 'gw poole':
        return GWpoole;
      case 'kimberley varsprodukte':
        return Kimberley;
      case 'protea':
        return Protea;
      case 'subtropico':
        return subtropico;
      case 'wenpro':
        return wenpro;
      case 'zire':
        return zire;
      case 'modise/bam':
        return modise;
      default:
        break
    }
  }

 const renderFruitIcon = () => {
   const fruitArray: Array<fruitCategoryType> = []

   for (let v  in fruitData) {
     if(fruitData[v as fruitCategoryType]) {
      fruitArray.push(v as fruitCategoryType);
     }
   }

   const renderFruit = (category: fruitCategoryType) => {
    switch (category) {
      case 'bananas':
        return <BananaIcon lang={lang} />;
      case 'apples':
        return <Apple lang={lang} />
      case 'carrots':
        return <Carrot lang={lang} />;
      case 'citrus':
        return <Citrus lang={lang} />
      case 'fruit':
        return <FruitsIcon lang={lang} />;
      case 'garlic':
        return <Garlic lang={lang} />;
      case 'ginger':
        return <Ginger lang={lang} />;
      case 'grapes':
        return <Grapes lang={lang} />;
      case 'onions':
        return <Onions lang={lang} />;
      case 'potatoes':
        return <PotatoesIcon lang={lang} />;
      case 'subtropical_fruits':
        return <FruitsIcon lang={lang} />;
      case 'tomatoes':
        return <TomatoesIcon lang={lang} />;
      case 'vegetables':
        return <VegetablesIcon lang={lang} />;
      case 'watermelon':
        return <WaterMelon lang={lang} />;
      case 'general':
        return <General lang={lang} />
      default:
        return <FruitsIcon lang={lang} />
     }
   }

   return fruitArray.map((category, index) => {
    return (
      <div key={index}>
        {renderFruit(category)}
      </div>
    )
   })
 }

  return (
    <article className={`flex flex-col justify-start shadow-xl ${className}`}>
     <div className="h-60 relative">
       {image?.src ?
          <Image src={image.src} alt={image?.alt || ''} className="h-full object-cover w-full" />
          : <div className="bg-carrot-orange flex justify-center items-center h-full"><NoPhoto /> </div>}

        {isManager && <div className="absolute top-4 right-4"><ManagerIcon /></div>}
     </div>
     <div className="p-5 flex-1">
        <Text type="H4" className="text-carrot-orange text-2xl font-light mb-5">{agentName}</Text>
       <div className="flex justify-between pb-4 border-b-xs border-casper">
          <div>
            <Text type="P" className="text-hunter-green flex-wrap">{businessName}</Text>
            <Text type="P" className="text-hunter-green flex-wrap">{province}</Text>
          </div>
          <Image src={renderAgentLogo()} alt={`${businessName} logo`} className="w-10 object-contain" />
       </div>
       <div className="flex flex-col overflow-visible">
        {Object.keys(contactInfo).map((info, index) => (
          <Text type="P" className="text-dove-grey text-sm leading-7 grid grid-cols-5 " key={index}>
            <span className="pr-2 capitalize">{info}:</span>
            <Link type="external" to={info === 'email' ? `mailto:${contactInfo[info as contactInfoDataType]}` : `tel:${contactInfo[info as contactInfoDataType]}`} className="underline col-span-4 break-all">
              {contactInfo[info as contactInfoDataType]}
            </Link>
          </Text>
        ))}
      </div>
     </div>
      <div className="my-3 items-center grid grid-cols-5 gap-2 px-5">
      {renderFruitIcon()}
      </div>
    </article>
  )
}

export default AgentCard
