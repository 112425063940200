import React, { useEffect, useState, } from "react";
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3'

import { Maxwidth, Input, Select, Textarea, Text, Link } from "../../atoms";
import useFormSubmit from "../../../../../../shared/src/hooks/useFormSubmit";

const formData = {
    name: "",
    province: "",
    company_name: "",
    email: "",
    tel: "",
    service: "",
    message: "",
};

const ContactForm: React.FC = () => {
    const { executeRecaptcha } = useGoogleReCaptcha()

    const [showForm, setShowForm] = useState(true);
    const [formValues, setFormValues] = useState(formData);
    const { handleSubmit, error, response, loading } = useFormSubmit();

    const handleForm = async (event: React.SyntheticEvent) => {
        event.preventDefault();

        if (!executeRecaptcha) {
            return
        }
        const token = await executeRecaptcha('marketAgentContactUs')

        if (!token) {
            // @ts-ignore
            Toast?.error?.("Could not verify recaptcha, please try again");
            return
        }

        const _formValues = {
            name: `Name: ${formValues.name}`,
            email: `Email: ${formValues.email}`,
            company_name: `Company Name: ${formValues.company_name}`,
            tel: `Contact number: ${formValues.tel}`,
            service: `Service: ${formValues.service}`,
            province: `Province: ${formValues.province}`,
            message: formValues.message
        }

        handleSubmit(_formValues, "market-agent-contact-form", token);
        setShowForm(false);
    };

    useEffect(() => {
        if (error) {
            // @ts-ignore
            Toast?.error?.("Operation failed. Try again");
        } else {
            if (response && !error) {
                // @ts-ignore
                setShowForm(false);
                setFormValues(formData);
            }
        }
    }, [error, response]);

    const handleChange = (e: { target: { name: string; value: string } }) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    };

    return (
        <Maxwidth>
            <section className="grid lg:grid-cols-2 gap-y-5 gap-x-24">
                {showForm ? (
                    <form onSubmit={handleForm}>
                        <Input
                            handleChange={handleChange}
                            value={formValues?.name}
                            label="Enter your name"
                            isRequired
                            name="name"
                        />

                        <Input
                            handleChange={handleChange}
                            value={formValues?.company_name}
                            label="Enter your company name"
                            name="company_name"
                        />
                        <Input
                            handleChange={handleChange}
                            value={formValues?.email}
                            type="email"
                            label="Enter your email address"
                            name="email"
                            isRequired
                        />
                        <Input
                            handleChange={handleChange}
                            value={formValues?.tel}
                            type="tel"
                            label="Enter your contact number"
                            name="tel"
                            isRequired
                        />
                        <Select
                            handleChange={handleChange}
                            name="service"
                            value={formValues?.service}
                            label="I am looking for"
                            id="service"
                        >
                            <option value="A salesperson">A salesperson</option>
                            <option value="Technical info">Technical info</option>
                            <option value="Sales">Sales</option>
                            <option value="Support">Support</option>
                            <option value="Other">Other</option>
                        </Select>
                        <Select
                            handleChange={handleChange}
                            name="province"
                            value={formValues?.province}
                            label="Select your province"
                            id="province"
                        >
                            <option value="Eastern Cape">Eastern Cape</option>
                            <option value="Free State">Free State</option>
                            <option value="Gauteng">Gauteng</option>
                            <option value="KwaZulu-Natal">KwaZulu-Natal</option>
                            <option value="Limpopo">Limpopo</option>
                            <option value="Mpumalanga">Mpumalanga</option>
                            <option value="Northern Cape">Northern Cape</option>
                            <option value="North West">North West</option>
                            <option value="Western Cape">Western Cape</option>
                        </Select>
                        <Textarea label="Your message" id="message" name="message" value={formValues?.message} handleChange={handleChange} isRequired />
                        <div className="text-center">
                            <button
                                disabled={loading}
                                type="submit"
                                className="text-center bg-carrot-orange py-1 px-12 text-white"
                            >
                                {loading ? "Submiting..." : "Submit"}
                            </button>
                        </div>
                    </form>
                ) : (
                    <div className="my-16">
                        <Text type="P" className="bg-orange-red text-white p-5">
                            Message Sent
                        </Text>
                    </div>
                )}

                <aside className="lg:mt-16">
                    <div className="mb-8">
                        <Text type="P" className="text-carrot-orange font-semibold mb-1">
                            Subtropico Limited
                        </Text>

                        <div className="text-dove-grey md:w-6/12 lg:w-full xl:w-6/12 grid grid-cols-5">
                            <Text type="P">Tel:</Text>
                            <Link to="tel:+270124609910" type="external" className="col-span-4">
                                +27 012 460 9910
                            </Link>
                        </div>
                    </div>
                    <div className="mb-8">
                        <Text type="P" className="text-carrot-orange font-semibold mb-1">
                            Email:
                        </Text>
                        <div className="text-dove-grey">
                            <Text type="P">
                                <Link to="mailto:info@subtropico.co.za" type="external">
                                    info@subtropico.co.za
                                </Link>
                            </Text>
                        </div>
                    </div>
                    <div className="mb-8">
                        <Text type="P" className="font-semibold mb-1 text-carrot-orange">
                            Physical address:
                        </Text>
                        <div className="text-dove-grey">
                            <Text type="P">
                                <Link to="https://goo.gl/maps/X1MKNAWrbcM66ery6" type="external">
                                    No. 2 Parkland Buildings, <br />
                                    229 Bronkhorst Street, <br />
                                    New Muckleneuk, Pretoria
                                </Link>
                            </Text>
                        </div>
                    </div>

                    <div className="mb-8">
                        <Text type="P" className="font-semibold mb-1 text-dove-grey">
                            Whistleblowing / <br />
                            Ethics Tip-off Hotline
                        </Text>
                        <div className="text-dove-grey md:w-6/12 lg:w-full xl:w-6/12 grid grid-cols-4 break-words">
                            <Text type="P">Tel:</Text>
                            <Link to="tel:+270124609910" type="external" className="col-span-3">
                                +27 11 488 1883
                            </Link>
                            <Text type="P">Email:</Text>
                            <Link
                                to="mailto:hotline@statucor.co.za"
                                type="external"
                                className="col-span-3"
                            >
                                hotline@statucor.co.za
                            </Link>
                        </div>
                    </div>
                </aside>
            </section>
        </Maxwidth>
    );
};

export default ContactForm;
