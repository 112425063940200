import React from 'react'
import { Text, Link, LimitedLogo, Maxwidth } from '@subtropico/shared';
import { getButtonhref } from '@subtropico/shared/utils'
import { Button } from '../../../../types/template';
import { useGlobalStateContext } from '../../../context/app';

interface ILimitedCard {
  title?: string,
  button?: Button
  imageUrl?: string
}

const LimitedCard: React.FC<ILimitedCard> = ({ title, button, imageUrl }) => {
  const { pageContextState } = useGlobalStateContext()
  
  return(
    <article className="shadow-sm bg-center bg-cover bg-no-repeat lg:h-56" style={{ backgroundImage: `url(${imageUrl})` }}>
      <Maxwidth className="xl:w-9/12 lg:w-11/12 mx-auto flex flex-col lg:flex-row items-center h-full px-4 lg:px-0">
        <div className="self-start mb-3 lg:mb-0"><LimitedLogo /></div>
        <div className="xl:w-5/12 lg:w-6/12 lg:ml-24 py-5 lg:py-0">
          <Text type="H5" className="text-payne-grey text-3xl font-light leading-8 mb-5">{title}</Text>

        <Link to={getButtonhref(button?.buttonHref, pageContextState?.locale)} type="external"
          className="bg-payne-grey text-sm font-medium text-white border border-payne-grey transition-colors duration-1000 hover:bg-transparent hover:text-payne-grey py-2 px-5 tracking-wide">
            {button?.buttonText?.[pageContextState?.locale!]}</Link>
        </div>
      </Maxwidth>
    </article>
  )
}

export default LimitedCard