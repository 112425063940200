import React from 'react';
import { CardWide as ICardWide } from '../../../types/template';
import ContactCard from '../Cards/ContactCard';
import LimitedCard from '../Cards/LimitedCard';
import ContactAltCard from '../Cards/ContactAlt';
import { Maxwidth } from '../../atoms';
import { useGlobalStateContext } from '../../context/app';
import { sanityImageURL } from '../../../utils'


const CardWide: React.FC<ICardWide> = ({ cardHeading, cardStyle, button, image, body }) => {
  const { pageContextState } = useGlobalStateContext()
  
  switch (cardStyle) {
    case 'wide-card-contact-us':
      return <Maxwidth>
        <ContactCard title={cardHeading?.[pageContextState?.locale!]} button={button!} />
      </Maxwidth>
    
    case 'contact-market-agentis-buyers-wide-card':
      return <Maxwidth>
        <ContactAltCard body={body} button={button!} />
      </Maxwidth>
    
    case 'contact-market-agentis-agents-wide-card':
      return <Maxwidth>
        <ContactAltCard body={body} button={button!} />
      </Maxwidth>
    
    case 'contact-market-agentis-producers-wide-card':
      return <Maxwidth>
        <ContactAltCard body={body} button={button!} />
      </Maxwidth>
    
    case 'limited-super-wide-card':
      return <LimitedCard title={cardHeading?.[pageContextState?.locale!]} button={button!} imageUrl={sanityImageURL(image)!} />
    
    default:
      return <div>No component available for {cardStyle}</div>
  }
}

export default CardWide;
