import React from 'react'
import { Link, PortableTextRender } from '@subtropico/shared'
import { getUrl } from '@subtropico/shared/utils'
import { Button } from '../../../../types/template';
import { useGlobalStateContext } from '../../../context/app'
import { SimpleContentBlockType, Lang } from '@subtropico/shared/types'

interface IContactCard {
  body?: { [key in Lang]: SimpleContentBlockType[] } [],
  button?: Button
}

const ContactAltCard: React.FC<IContactCard> = ({ body, button }) => {
  const { pageContextState } = useGlobalStateContext()
  
  return (
    <article className="py-16 md:px-10 px-5 h-full shadow-fullSides-sm flex flex-col lg:flex-row space-y-5 lg:space-y-0 justify-between items-center my-16">
      <div className="lg:w-9/12 w-full">
        {pageContextState?.locale ? body?.map((item, i) => <PortableTextRender body={item?.[pageContextState?.locale!]} key={i} />):''}
      </div>
      <Link to={getUrl(pageContextState?.locale!, button?.buttonLink?.slug?.[pageContextState?.locale!]?.current)}
        className="py-2 px-6 bg-carrot-orange border border-carrot-orange text-white transition-colors duration-1000 hover:bg-transparent hover:text-carrot-orange">
        {button?.buttonText?.[pageContextState?.locale!]}</Link>
    </article>
  )
}

export default ContactAltCard