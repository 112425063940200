import React from 'react';
import { CardCollection as ICardCollection } from '../../../types/template';
import { Maxwidth, Image } from '../../atoms';
import { ArticleCard, PortableTextRender, Slider } from '../../molecules';
import { useGlobalStateContext } from '../../context/app'
import { sanityImageURL } from '../../../utils'


const CardCollection: React.FC<ICardCollection> = ({ cardCollectionStyle, cards, intro }) => {

  const { pageContextState } = useGlobalStateContext()

  switch (cardCollectionStyle) {
   case 'read-more-large-card-collection':
      return <div className="pb-20 pt-10 shadow-fullShadow w-full mb-10">
        <Maxwidth className="xl:w-7/12 lg:w-7/12 mb-5 -mt-10">
          <div>
            {intro? <PortableTextRender body={intro} /> : ''}
          </div>
        </Maxwidth>
        <Maxwidth>
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-10 my-8">
            {cards?.map((card, index) => (
              <div className="h-72" key={index}>
                <ArticleCard lang={pageContextState?.locale!} link={card?.cardLinks?.[0]} heading={card?.heading?.[pageContextState?.locale!]} imageUrl={sanityImageURL(card?.image)!} type='lg' />
              </div>
            ))}
          </div>
        </Maxwidth>
      </div>

    case 'read-more-small-card-collection':
      return <div className="my-10 pb-5">
        <Maxwidth>
          <div className="my-5">
            {intro? <PortableTextRender body={intro} /> : ''}
          </div>
          <div className="grid xl:grid-cols-5 lg:grid-cols-4 md:grid-cols-2 gap-10 h-44">
            {cards?.map((card, index) => (
              <ArticleCard lang={pageContextState?.locale!} key={index} link={card?.cardLinks?.[0]} heading={card?.heading?.[pageContextState?.locale!]} imageUrl={sanityImageURL(card?.image)!} />
            ))}
          </div>
        </Maxwidth>
      </div>

    case 'stats-grid-card-collection':
      return <Maxwidth className='xl:w-6/12 lg:w-8/12'>
          <div className="py-20 grid md:grid-cols-3 gap-y-5 h-full place-content-center md:place-content-start">
          {cards?.map((card, index) => (
            <Image alt={card?.title!} className="h-52 pb-4" src={sanityImageURL(card?.image)!} key={index} />
          ))}
          </div>
      </Maxwidth>

    case 'partner-logo-card-collection':
      return <div className="mt-10 mb-16">
        <Maxwidth>
          <div className="my-6 text-center">
            {intro? <PortableTextRender body={intro} /> : ''}
          </div>
          <Slider>
            {cards?.map((card, index) => (
              <Image src={sanityImageURL(card?.image)!} alt={card.title || 'partner'} className="object-contain h-16" key={index} />
            ))}
          </Slider>
        </Maxwidth>
      </div>

   default:
      return <>No component available for {cardCollectionStyle}</>
 }

}

export default CardCollection;
