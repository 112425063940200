import React, { useState, useEffect } from 'react'
import { useStaticQuery, graphql } from "gatsby"
import { useLocation } from "@reach/router"
import { ProductList, MarketList as MarketListType, List } from "../../../types/index"
import MarketCard from "../Cards/MarketCard";
import AgentCard from '../Cards/AgentCard';
import { Maxwidth, Selector } from "../../atoms"
import { sanityImageURL } from '../../../utils'
import { useGlobalStateContext } from '../../context/app'
import useScrollDirection from '@subtropico/shared/src/hooks/useScrollDirection';


const useMarketList = () => {
  const { marketList, agentsList, distributionCenters }: ProductList = useStaticQuery(
    graphql`
      query AllMarketList {
        marketList: allSanitySubtropicoMarket {
          nodes {
            name {
              af
              en
            }
            physical_address {
              af
              en
            }
            postal_address {
              af
              en
            }
            province {
              af
              en
            }
            telephone
            image: _rawImage(resolveReferences: {maxDepth: 2})
          }
        }
        agentsList: allSanitySubtropicoMarketAgent {
          nodes {
            image: _rawImage
            apples
            bananas
            carrots
            cell_number
            citrus
            email
            fax_number
            fruit
            logo
            garlic
            general
            ginger
            grapes
            manager
            name
            onions
            potatoes
            subtropical_fruits
            tomatoes
            vegetables
            watermelon
            distributionCenter {
              name {
                af
                en
              }
            }
            market {
              name {
                en
                af
              }
            }
          }
        }
        distributionCenters: allSanitySubtropicoDistributionCenter {
          nodes {
            name {
              af
              en
            }
          }
        }
      }
    `)
  return { marketList, agentsList, distributionCenters }
}

const commodityList = ['All commodities', 'Bananas', 'Carrots', 'Fruit', 'Onions', 'Potatoes', 'Tomatoes', 'Vegetables', 'Apples', 'Citrus', 'Garlic', 'General', 'Ginger', 'Grapes', 'Watermelon', 'Subtropical Fruits']

const MarketList: React.FC = () => {

  const location = useLocation();
  const { pageContextState } = useGlobalStateContext()
  const { marketList, agentsList, distributionCenters } = useMarketList()
  const lang = pageContextState?.locale
  const [loading, setLoading] = useState(true)
  const [allMarketList, setAllMarketList] = useState<MarketListType[]>([])
  const [agents, setAgents] = useState<List[]>([])
  const [filterSelector, setFilterSelector] = useState({
    commodity: 'Select a commodity',
    distributionCenter: 'Select a Distribution Centre',
    market: 'Select one of our markets',
  })
  const [markList, setMarkList] = useState<string[]>([])
  const [showLink, setShowLink] = useState<boolean>(true)
  const [searchTerm, setSearchTerm] = useState('')
  const [showLoadMore, setShowLoadMore] = useState<boolean>(false)
  const [distCenters, setDistCenters] = useState<string[]>([])
  const { isScrollingDown } = useScrollDirection();

  const updateCommodity = (value: string) => {
    setFilterSelector((prev) => ({ ...prev, commodity: value}))
  }
  const updateDistributionCenter = (value: string) => {
    setFilterSelector((prev) => ({ ...prev, distributionCenter: value}))
  }
  const updateMarket = (value: string) => {
    setFilterSelector((prev) => ({ ...prev, market: value}))
  }

  useEffect(() => {
    setLoading(true)
    if (marketList?.nodes.length) {
      const allList = [...marketList?.nodes]
      if (allList.length > 12) {
        allList.length = 12
        setShowLoadMore(true)
      }
      setAllMarketList(allList)
    }
    setLoading(false)
  }, [marketList])


  useEffect(() => {
    if (location.search) {
      const search = location.search.replace('?', '').split('=')
      if (search[0] === 'market') {
        const _marketValue = search[1].replaceAll('%20', ' ').replaceAll('+', ' ')
        setFilterSelector((prev) => ({ ...prev, market: _marketValue }))
      }
    }
  }, [location])

  useEffect(() => {
    const _markets: string[] = ['All Province']
    marketList?.nodes?.map((center) => {
      center.province?.[pageContextState?.locale!] && _markets.push(center.province?.[pageContextState?.locale!]!)
    })
    const _marketList = Array.from(new Set(_markets))
    setMarkList(_marketList)
  }, [marketList, pageContextState?.locale])

  useEffect(() => {
    const _centers: string[] = ['All Distribution centers']
    distributionCenters?.nodes?.map((center) => {
      _centers.push(center.name?.[pageContextState?.locale!]!)
    })

    setDistCenters(_centers)
  }, [distributionCenters, pageContextState?.locale])

  useEffect(() => {
    setLoading(true)
    const isMarketSelected = filterSelector.market === 'Select one of our markets' || filterSelector.market === 'All Province'
    const isCommoditySelected = filterSelector.commodity === 'Select a commodity' || filterSelector.commodity === 'All commodities'
    const isDistSelected = filterSelector.distributionCenter === 'Select a Distribution Centre' || filterSelector.distributionCenter === 'All Distribution centers'
    const _marketList = marketList?.nodes || []
    let filteredList: MarketListType[] | undefined = [..._marketList]
    
    if (isCommoditySelected) {
      filteredList = filteredList
    } else {
      const _filteredList: string[] = []
      let _commodity = filterSelector.commodity.toLowerCase()
      _commodity = _commodity.replace(' ', '_')

      agentsList?.nodes.filter((list) => {
        if (list?.[_commodity]) {
          _filteredList.push(list.market.name?.[pageContextState?.locale!]!?.trim())
        }
      })

      let newFilter: any = Array.from(new Set(_filteredList))

      newFilter = marketList?.nodes?.filter((list) => newFilter?.includes(list.name?.[pageContextState?.locale!]))
      filteredList = newFilter
    }

    if (isMarketSelected) {
      filteredList = filteredList
    } else {
      let newFilter: MarketListType[] | undefined = filteredList?.filter((list) => list?.province?.[pageContextState?.locale!] === filterSelector.market)
      filteredList = newFilter
    }

    if (isDistSelected) {
      filteredList = filteredList
    } else {
      const _filteredList: string[] = []
      let _distributionCenter = filterSelector.distributionCenter.toLowerCase()

      agentsList?.nodes.filter((list) => {
        if (list?.distributionCenter?.name?.[pageContextState?.locale!].toLowerCase() === _distributionCenter) {
          _filteredList.push(list.market.name?.[pageContextState?.locale!]!?.trim())
        }
      })

      let newFilter: any = Array.from(new Set(_filteredList))
      
      newFilter = filteredList?.filter((list) => newFilter?.includes(list.name?.[pageContextState?.locale!]))
      filteredList = newFilter
    }

    setAllMarketList(filteredList || [])
    setAgents([])
    setLoading(false)
    setShowLoadMore(false)
    setShowLink(true)
  }, [filterSelector])


  const viewMarketAgent = (_marketName: string) => {
    let _filteredAgents: List[] | undefined = []
    if (filterSelector.commodity === 'Select a commodity' || filterSelector.commodity === 'All commodities') {
      _filteredAgents = agentsList?.nodes.filter((agentInfo) => agentInfo.market.name?.[pageContextState?.locale!]!?.trim() === _marketName.trim())
    } else {
      let _commodity = filterSelector.commodity.toLowerCase()
      _commodity = _commodity.replace(' ', '_')  
      _filteredAgents = agentsList?.nodes.filter((agentInfo) => agentInfo.market.name?.[pageContextState?.locale!]!?.trim() === _marketName.trim() && agentInfo?.[_commodity])      
    }
    setAgents(_filteredAgents || [])

    if (_filteredAgents?.length) {
      const _filteredMarketList = marketList?.nodes.filter((marketList) => marketList.name?.[pageContextState?.locale!]!?.trim() === _marketName.trim())
      setAllMarketList(_filteredMarketList || [])
      setShowLink(false)
    }
    if (filterSelector.commodity === 'Select a commodity' || filterSelector.commodity === 'All commodities') {
      setSearchTerm(`${_marketName} | All commodities`)
    } else {
      setSearchTerm(`${_marketName} | ${filterSelector.commodity}`)
    }
  }

  useEffect(() => {
    setLoading(true)
    if (marketList?.nodes.length) {
    const allList = [...marketList?.nodes]
      if (allList.length > 12) {
        allList.length = 12
        setShowLoadMore(true)
      }
        setAllMarketList(allList)
    }
    setLoading(false)
  }, [marketList])

  const showAllMarket = () => {
    setAllMarketList(marketList?.nodes!)
    setShowLoadMore(false)
  }

  return (
    <section className="my-10">
      <div className={`sticky z-10 ${isScrollingDown ? 'top-0' : 'top-28'}`}>
        <div className="my-8 shadow-bottom bg-white">
          <Maxwidth>
            <div className="grid md:grid-cols-3 gap-10">
              <Selector selectorOptions={commodityList} value={filterSelector.commodity} setValue={updateCommodity} hoverColor="agentlist-green" />
              <Selector selectorOptions={markList} value={filterSelector.market} setValue={updateMarket} hoverColor="agentlist-green" />
              <Selector selectorOptions={distCenters} value={filterSelector.distributionCenter} setValue={updateDistributionCenter} hoverColor="agentlist-green" />
            </div>
          </Maxwidth>
        </div>
      </div>
      <div id="listing" className="-mt-60 pt-60 md:-mt-48 md:pt-48 relative z-0">
        <Maxwidth>
          {loading ? <div className="animate__animated animate__bounce animate__slow animate__infinite flex justify-center items-center my-10 text-2xl text-orange-red">
            Loading...
          </div> : allMarketList?.length ? <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-10 animate__animated animate__fadeIn animate__slow">
          {allMarketList.sort((a,b) => a?.name?.[lang!].localeCompare(b?.name?.[lang!]!)!).map((market, index) => {
              return (
                <MarketCard
                  image={market?.image ? { src: sanityImageURL(market?.image), alt: market?.name?.[pageContextState?.locale!] } : undefined}
                  key={index}
                  marketName={market.name?.[pageContextState?.locale!]!}
                  province={market.province?.[pageContextState?.locale!]!}
                  className="animate__animated animate__fadeInLeft animate__faster"
                  address={market?.physical_address?.[pageContextState?.locale!]!}
                  postal={market?.postal_address?.[pageContextState?.locale!]!}
                  handleMarket={() => viewMarketAgent(market.name?.[pageContextState?.locale!]!)}
                  showLink={showLink}
                />
              )
            }
          )}
            </div> : <div className="flex justify-center items-center my-10 text-2xl text-payne-grey">
              No Items match your search
            </div>}
          {agents.length ?
            <div className="my-10 grid lg:grid-cols-3 md:grid-cols-2 gap-10 animate__animated animate__fadeIn animate__slow">
              <div className="lg:col-span-3 md:col-span-2 text-xl text-payne-grey animate__animated animate__faster animate__bounceIn">
                Results for your search: {searchTerm}
              </div>
              {agents?.sort((a,b) => a.name.localeCompare(b.name)).map((agent, index) => {
                return (
                  <AgentCard
                    lang={pageContextState?.locale!}
                    image={agent?.image ? { src: agent?.image?._upload ? agent?.image?._upload?.previewImage : sanityImageURL(agent?.image), alt: agent?.name } : undefined}
                    key={index}
                    businessName={agent?.market.name?.[pageContextState?.locale!]!}
                    province={agent.market.province?.[pageContextState?.locale!]}
                    agentLogo={agent?.logo}
                    agentName={agent?.name}
                    isManager={agent?.manager}
                    contactInfo={{
                      fax: agent?.fax_number || '',
                      cell: agent?.cell_number || '',
                      email: agent?.email || ''
                    }}
                    className="animate__animated animate__fadeInLeft animate__faster"
                    fruitData={{
                      apples: !!agent.apples,
                      bananas: !!agent.bananas,
                      carrots: !!agent.carrots,
                      citrus: !!agent.citrus,
                      fruit: !!agent.fruit,
                      garlic: !!agent.garlic,
                      ginger: !!agent.ginger,
                      grapes: !!agent.grapes,
                      onions: !!agent.onions,
                      potatoes: !!agent.potatoes,
                      subtropical_fruits: !!agent.subtropical_fruits,
                      tomatoes: !!agent.tomatoes,
                      vegetables: !!agent.vegetables,
                      watermelon: !!agent.watermelon,
                      general: !!agent.general,
                    }}
                  />
                )
              }
              )}
            </div>
          : ''}
          {showLoadMore ?
            <div className="text-center my-14">
              <button onClick={showAllMarket} className="bg-hunter-green border-2 border-hunter-green text-white py-4 px-14 transition-colors hover:bg-transparent duration-500 hover:text-hunter-green">Load All Markets</button>
            </div>
            : ''}
        </Maxwidth>
      </div>
    </section>
  )
}

export default MarketList
