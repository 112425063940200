import React from 'react';
import { Maxwidth } from '../../atoms'
import { PortableTextRender } from '../../molecules'
import { Lang, SimpleContentBlockType } from '@subtropico/shared/types'

interface SimpleContentBlockProp {
  body: {[key in Lang]: SimpleContentBlockType[] }[]
  lang: Lang
}

const SimpleContentBlock: React.FC<SimpleContentBlockProp> = ({ body, lang="en" }) => (
    <Maxwidth className="lg:w-7/12">
      {body?.map((item, i) => <PortableTextRender body={item?.[lang]} key={i} /> )}
    </Maxwidth>
)

export default SimpleContentBlock;
