import React from 'react'
import { Text, Link, MarketAgentLogo } from '@subtropico/shared'
import { getUrl, getButtonLink } from '@subtropico/shared/utils'
import { Button } from '@subtropico/shared/types';
import { useGlobalStateContext } from '../../../context/app';

interface IContactCard {
  title?: string,
  button?: Button
}

const ContactCard: React.FC<IContactCard> = ({ title, button }) => {
  const { pageContextState } = useGlobalStateContext()
  return (
    <article className="py-5 px-10 h-full shadow-fullSides-sm flex flex-col lg:flex-row space-y-5 lg:space-y-0 justify-between items-center my-16">
      <div><MarketAgentLogo /></div>
      <Text type="P" className="text-payne-grey text-2xl lg:text-2xl xl:text-3xl font-normal">{title}</Text>
      <Link to={getUrl(pageContextState?.locale!, getButtonLink(button?.buttonLink, pageContextState?.locale))} 
      className="py-2 px-6 font-medium bg-carrot-orange border border-carrot-orange text-white transition-colors duration-1000 hover:bg-transparent hover:text-carrot-orange">
        {button?.buttonText?.[pageContextState?.locale!]}</Link>
    </article>
  )
}

export default ContactCard;
