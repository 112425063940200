import _sanityClient from '@sanity/client';
import imageUrlbuilder from '@sanity/image-url'

const sanityClient = _sanityClient({
  projectId: process.env.GATSBY_SANITY_PROJECT_ID,
  dataset: 'marketagents',
  apiVersion: '2021-11-25',
  useCdn: true
});

const builder = imageUrlbuilder(sanityClient);

const imageURLBuilder = (source: any) => builder.image(source);
export const sanityImageURL = (image: any) => {
  return image ? imageURLBuilder(image).url() : '';
}
