import React, { useEffect, useMemo, useState } from "react";
import { graphql } from "gatsby";
import Layout from "../../common/components/template/Layout";
import { PagePart, PageProp } from "../../common/types";
import { useGlobalStateContext } from "../../common/components/context/app";
import PagePartRenderer from "../PagePartRenderer";
import Toast from "../../../../shared/src/components/atoms/Toast";

// This would be updated soon: Just for test purposes. I believe map frame would come from sanity data.
// Function adds mapRender to the page parts
const addMapToPage = (rec: PagePart[] | undefined) => {
    if (!rec?.length) return rec;
    const newParts: PagePart[] = [];
    rec.forEach((part: any) => {
        newParts.push(part);
        if (part._key === "76c12f6b932d") {
            newParts.push({
                source: "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3592.9137501989726!2d28.22819025163093!3d-25.773412252778808!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1e95610b41627f57%3A0x9bd895a7011cef19!2sSubtropico%20Ltd!5e0!3m2!1sen!2sza!4v1678288789941!5m2!1sen!2sza",
                height: "600",
                width: "100%",
                _type: "mapRender",
            });
        }
    });

    return newParts;
};

const Template: React.FC<PageProp> = ({ data, pageContext }) => {
    const pageParts = data?.pageData?.pageParts;

    const { setSiteConfig, pageContextState } = useGlobalStateContext();
    const [lang] = useState(pageContextState?.locale || "en");

    useEffect(() => {
        setSiteConfig &&
            pageContext &&
            setSiteConfig({
                payload: pageContext,
                type: "SET",
            });
    }, [pageContext]);

    const pagePartsWithMap = useMemo(() => addMapToPage(pageParts), [pageParts]);
    return (
        <Layout
        metaDescription={pageContextState?.metadata?.page_description?.[lang]} 
        metaTitle={pageContextState?.metadata?.page_title?.[lang]} 
        meta={[]} 
        lang={lang}>
            <Toast />
            {pageParts &&
                pagePartsWithMap?.map((part, i) => (
                    <PagePartRenderer
                        lang={pageContextState?.locale || "en"}
                        part={part}
                        key={i}
                        id={i}
                    />
                ))}
        </Layout>
    );
};

export default Template;

export const query = graphql`
    query Page($id: String!) {
        pageData: sanityPage(_id: { eq: $id }) {
            pageParts {
                ... on SanityHero {
                    _key
                    _type
                    body {
                        af: _rawAf(resolveReferences: { maxDepth: 3 })
                        en: _rawEn(resolveReferences: { maxDepth: 3 })
                        _type
                    }
                    image: _rawImage(resolveReferences: { maxDepth: 1 })
                    partStyle
                    title
                    overlayCard {
                        _key
                        _type
                        cardStyle
                        title
                        partStyle
                        navanchor
                        cardHeading {
                            af
                            en
                            _type
                        }
                    }
                }

                ... on SanitySimpleContentBlock {
                    title
                    _key
                    _type
                    body {
                        af: _rawAf(resolveReferences: { maxDepth: 10 })
                        en: _rawEn(resolveReferences: { maxDepth: 10 })
                        _type
                    }
                }

                ... on SanityCardWide {
                    _key
                    _type
                    cardStyle
                    body {
                        af: _rawAf
                        en: _rawEn
                        _type
                    }
                    title
                    cardHeading {
                        af
                        en
                    }
                    image: _rawImage(resolveReferences: { maxDepth: 1 })
                    button {
                        buttonHref {
                            af
                            en
                        }
                        buttonText {
                            af
                            en
                        }
                        buttonStyle {
                            af
                            en
                        }
                        buttonLink {
                            ... on SanityPage {
                                id
                                _type
                                slug {
                                    _type
                                    af {
                                        current
                                    }
                                    en {
                                        current
                                    }
                                }
                            }
                            ... on SanitySimpleDownloadAsset {
                                id
                                _type
                                downloadAsset {
                                    _type
                                    asset {
                                        url
                                    }
                                }
                            }
                        }
                    }
                }

                ... on SanityCardCollection {
                    _key
                    _type
                    cardCollectionStyle
                    title
                    heading {
                        af
                        en
                        _type
                    }
                    intro: _rawIntro(resolveReferences: { maxDepth: 2 })
                    partStyle
                    cards {
                        _id
                        _key
                        image: _rawImage
                        cardLinks {
                            _key
                            _type
                            linkURL
                            linkTitle
                            pageLink {
                                slug {
                                    af {
                                        current
                                    }
                                    en {
                                        current
                                    }
                                }
                            }
                        }
                        heading {
                            af
                            en
                        }
                        title
                        body {
                            af: _rawAf
                            en: _rawEn
                        }
                        subheading {
                            af
                            en
                        }
                    }
                }

                ... on SanityFormPart {
                    _key
                    _type
                    simpleForm {
                        formId
                        additionalContent: _rawAdditionalContent
                        body: _rawBody
                    }
                }

                ... on SanityAgentsList {
                    _key
                    _type
                    title
                    partStyle
                }
                ... on SanityMarketList {
                    _key
                    _type
                    title
                }
            }
        }
    }
`;
